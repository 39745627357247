import { render, staticRenderFns } from "./register.vue?vue&type=template&id=e5aeac2a&scoped=true&"
import script from "./register.ts?vue&type=script&lang=ts&"
export * from "./register.ts?vue&type=script&lang=ts&"
import style0 from "./register.vue?vue&type=style&index=0&id=e5aeac2a&prod&lang=css&scoped=true&"
import style1 from "./register.vue?vue&type=style&index=1&id=e5aeac2a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5aeac2a",
  null
  
)

export default component.exports