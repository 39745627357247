import { Vue, Component, Prop } from 'vue-property-decorator'
import { Session } from '@/modules/registration/interfaces/index.interface'
import moment from 'moment/moment'

@Component({})
export default class GtrSessionCheckbox extends Vue {
  @Prop({ required: true, default: [] })
  value!: Array<string>;

  @Prop()
  session!: Session;

  @Prop({ required: true, default: '_default' })
  currentRegType!: string;

  @Prop({ default: false })
  displayOptionPrices!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: null })
  hideDetails!: boolean | null;

  get selected () {
    return this.value.indexOf(this.session.uuid) !== -1
  }

  get price (): string {
    const pricing = this.session.pricing?.find(p => p.reg_type === this.currentRegType) ?? this.session.pricing?.find(p => p.reg_type === '_default')
    const price: string | undefined = pricing?.tiered_pricing?.[this.session.current_pricing_tier]?.price
    return price ? Number(price).toFixed(2) : ''
  }

  onToggle () {
    if (!this.disabled) {
      if (this.selected) {
        const updatedList = this.value.filter(uuid => uuid !== this.session.uuid)
        this.$emit('input', updatedList);
      } else {
        this.$emit('input', [...this.value, this.session.uuid]);
      }
    }
  }

  formatDate(date: string) {
    return moment(new Date(date)).format('MMMM D, YYYY h:mm A')
  }
}
