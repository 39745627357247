import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { Session } from '@/modules/registration/interfaces/index.interface'
import moment from 'moment'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import GtrSessionCheckbox from '@/modules/registration/views/components/gtr-session-checkbox/gtr-session-checkbox.vue'
import { VCard } from 'vuetify/lib/components'

@Component({
  name: 'GtrSessionField',
  components: {
    'gtr-session-checkbox': GtrSessionCheckbox
  }
})
export default class GtrSessionField extends Vue {
  @Prop({ required: true, default: [] })
  sessions: Array<Session> | undefined;

  @Prop({ required: true, default: [] })
  value!: Array<string>;

  @Prop()
  event: any;

  @Prop({ required: true, default: '_default' })
  currentRegType!: string;

  @Prop({ default: false })
  displayOptionPrices!: boolean;

  @Ref()
  searchRef!: InstanceType<typeof VCard>

  activeTab = 0;

  search = '';

  sortedSessions: Record<string, any> = {}

  async mounted() {
    try {
      this.sortSessions(this.sessions)
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  @Watch ('sessions')
  onSessionsChange (payload: Session[] | undefined) {
    this.sortSessions(payload)
  }

  get eventHasNoSessions (): boolean {
    return !this.sessions || this.sessions.length === 0
  }

  get selectedSessions (): string[] {
    return this.value
  }

  set selectedSessions (value: string[]) {
    this.$emit('input', value)
  }

  get tabs () {
    return Object.keys(this.sortedSessions)
  }

  get tabsContent () {
    return Object.values(this.sortedSessions)
  }

  /**
   * return search results
   */
  get searchResults (): Array<Session> {
    if (this.search) {
      return Object.values(this.sortedSessions)
        .flat()
        .filter(session => session.name.toLowerCase().includes(this.search.toLowerCase()))
    } else {
      return []
    }
  }

  /**
   * List of the days of the event.
   */
  get eventDates () {
    const { event_start_date: startDate, event_end_date: endDate } = this.event;
    return this.getDaysBetweenDates(startDate, endDate)
  }

  /**
   * reset the search
   */
  public closeSearch () {
    this.search = ''
  }

  public hasNoSessions (date: string): boolean {
    return !(date in this.sortedSessions) || this.sortedSessions[date].length === 0
  }

  /**
   * Create a new object for storing sessions by date.
   * @private
   */
  private initializeSessionByDateObject (): Record<string, any> {
    return {}
  }

  /**
   * Sorts the sessions for an event for use in the component.
   * @param sessions
   * @private
   */
  private sortSessions (sessions: Session[] | undefined) {
    const newSortedSession = {}
    const formattedEventDates: string[] = this.eventDates.map(date => moment(date).format('MMMM D, YYYY'))
    formattedEventDates.forEach(date => {
      newSortedSession[date] = []
    })
    if (sessions) {
      // put sessions in their buckets.
      sessions.forEach(session => {
        const startDate: Date = new Date(session.start_date)
        const formatedSessionStartDate = moment(startDate).format('MMMM D, YYYY')
        if (formatedSessionStartDate in newSortedSession) {
          newSortedSession[formatedSessionStartDate].push(session)
        }
      })
    }
    this.sortedSessions = newSortedSession
 }

  /**
   * Generates a list of dates between two dates.
   * @param start
   * @param end
   * @private
   */
  private getDaysBetweenDates (start: string, end: string): Date[] {
    const startDay: Date = new Date(start)
    const endDate: Date = new Date(end)
    const daysOfEvent: Date[] = [];
    const currentDate = new Date(startDay);
    while (currentDate <= endDate) {
      daysOfEvent.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysOfEvent
  }

  /**
   * Checks to see if a given overlaps with any others on the day it occurs.
   * @method checkIfSessionOverlaps
   * @param session
   */
  public checkIfSessionOverlaps (session: Session): boolean {
    const startOfSession = new Date(session.start_date)
    const endOfSession = new Date(session.end_date)
    const searchKey = moment(startOfSession).format('MMMM D, YYYY')
    const sessions = this.sortedSessions[searchKey]
    const otherSessions = sessions.filter((s: Session) => s.uuid !== session.uuid && this.selectedSessions.indexOf(s.uuid) !== -1)
    const overlapsWithOtherSessions: boolean[] = otherSessions.map((s: Session) => {
      const { start_date, end_date } = s
      const startDate = new Date(start_date)
      const endDate = new Date(end_date)
      return (startOfSession >= startDate && startOfSession < endDate) || (endOfSession > startDate && endOfSession <= endDate)
    })
    return overlapsWithOtherSessions.some(overlaps => overlaps)
  }

  formatDate(date: string) {
    return moment(new Date(date)).format('MMMM D, YYYY')
  }
}
